.tp-wrapper {
  &.goals {
    background: transparent;
    margin: 10px 40px 0 40px;
  }
}

.goals {
  &-item {
    background: #fff;
    padding: 30px 20px 15px 20px;
    border-radius: 5px;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &__date {
      font-size: 12px;
      color: #777;
    }

    &__title {
      font-weight: 700;
      font-size: 21px;
      padding: 0;
      text-transform: uppercase;
    }

    &_descr {
      margin-bottom: 15px;
      font-size: 14px;
    }

    &_main {
      display: flex;
      flex-wrap: wrap;

      &__info {
        display: flex;

        &__img {
          display: flex;
          align-items: center;
          margin-right: 38px;

          .icon-trophy1,
          .icon-flag3 {
            &:before {
              font-size: 24px;
            }
          }
        }

        &__item {
          max-width: 280px;
          margin-right: 65px;

          &:last-child {
            margin-right: 0;
          }

          &__title {
            color: #CED1D3;
            font-weight: 700;
            font-size: 17px;
          }
        }
      }

      &_btn {
        margin-left: auto;
        align-self: flex-end;

        .btn {
          font-size: 19px;
          padding-left: 25px;
          padding-right: 25px;
        }
      }
    }
  }
}

.tagebuch {
  .goals {
    &-item {
      &_main {
        &__info {
          &__item {
            max-width: inherit;
          }
        }
      }
    }
  }
}

.week-nav {
  .item {
    .week-nav-day {
      &.marathons-1 {
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          left: 50%;
          margin-left: 0;
          bottom: -7px;
          background: #f00;
        }
      }

      &.training-planed {
        &.intensity-0.marathons-1 {
          &:before {
            margin-left: -5px;
          }

          .number {
            color: #000;
          }
        }
      }
    }
  }
}

.inplanung-wrapper {
  background: #000;
  color: #fff !important;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 20px;
  margin-bottom: 25px;
  margin-top: 25px;

  .video {
    width: 90%;
    display: inline-block;
  }
  p {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-img {
    width: calc(100% + 44px);
    margin-top: -10px;
    margin-left: -22px;
  }

  h2 {
    color: #fff;
  }

  .training-btn-wrapper-flex {
    max-width: 350px;
    display: inline-flex !important;
  }
}

.nutrition-index-index,
.customer-trainingsplan-index {
  .training-btn-wrapper-flex-misc {
    align-items: stretch;
    display: flex;
    flex-direction: row;

    a {
      color: #fff;
      font-family: 'Open Sans';
      font-size: 14px;
      text-transform: none;
      height: 120px;
      line-height: 17px;
      font-weight: 700;
      background: rgba(255,255,255,0.2);
      flex: 1;
      margin-left: 8px;
      border-radius: 5px;
      text-decoration: none;
      margin-bottom: 10px;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
      perspective: 1000px;
      transition: all 0.1s ease-out;

      &:first-child {
        margin-left: 0;
      }

      &.btn-trainerkontakt,
      &.btn-awards {
        span {
          position: absolute;
          font-size: 50px;
          width: 100%;
          text-align: center;
          top: 20px;
        }
      }

      &.btn-uhr,
      &.btn-gespraech {
        text-transform: uppercase;
        color: #000;
        background: linear-gradient(310deg, rgba(255,168,0,1) 0%, rgba(255,225,61,1) 100%);
        display: flex;
        height: 120px;
        text-align: left;

        span,
        div {
          display: block;
          margin: auto 0;
        }

        .icon-smartphone {
          width: 60px;
          font-size: 50px;
          margin-left: 10px;
        }
      }

      > div {
        padding: 0 12px;
      }

      &:hover {
        box-shadow: inset 0 0 0 2px #fff;
        transform: scale(1);
      }

      @media screen and (max-width: 992px) {
        &.btn-trainerkontakt,
        &.btn-awards {
          flex-basis: calc(50% - 5px);
          font-size: 14px;
        }

        &.btn-uhr,
        &.btn-gespraech {
          flex-basis: calc(100%);
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) and (min-width: 1199px) {
  .goals {
    &-item {
      &_main {
        &_btn {
          .btn {
            font-size: 16px;
            padding-left: 18px;
            padding-right: 18px;
            height: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .tp-wrapper {
    &.goals {
      margin: 10px 0 0 0;
    }
  }
}